<template>
  <v-card
    elevation="0">
    <v-card-title>{{ t("details.group-title") }}</v-card-title>
    <v-card-text>
      <v-row class="sp">
        <v-col>
          <v-text-field
            :label="t('details.name')"
            v-model="dataInternal.name"
            counter="120"
            filled
            :rules="[
								() => !!dataInternal.name || $t('validation.required.default'),
								() => (dataInternal.name && dataInternal.name.length <= 120) || $t('validation.length.default')
							]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-checkbox
          v-model="timelog_active"
          :label="t('details.timelog_active')" />
      </v-row>
      <v-row v-if="timelog_active">
        <v-autocomplete
          v-model="customers.customer_id"
          :loading="customers.loading"
          :items="customers.data"
          item-text="display_name"
          item-value="id"
          :search-input.sync="customers.search"
          filled
          hide-no-data
          :label="t('details.customer')"
          append-icon="mdi-magnify"
          clearable
          :spellcheck="false"
          data-cy="customer"
        />
      </v-row>
      <v-row v-if="timelog_active && customers.customer_id">
        <v-autocomplete
          v-model="dataInternal.timelog_project"
          :loading="projects.loading"
          :items="projects.data"
          item-text="name"
          item-value="id"
          :search-input.sync="projects.search"
          filled
          hide-no-data
          :label="t('details.project')"
          append-icon="mdi-magnify"
          clearable
          :spellcheck="false"
          data-cy="project"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>

import { HTTP } from "@/auth"

export default {
  name: "AbesncesReasonsContent",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({ name: "" })
    }
  },
  data() {
    return {
      dataInternal: {
        name: this.value.name,
        timelog_project: this.value.timelog_project?.id
      },
      timelog_active: this.value.timelog_project != null,
      customers: {
        loading: false,
        data: [
          this.value.timelog_customer
        ],
        search: "",
        customer_id: this.value.timelog_customer?.id
      },
      projects: {
        loading: false,
        data: [
          this.value.timelog_project
        ],
        search: ""
      }
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.dataInternal)) {
          this.dataInternal = { ...newValue }
        }
      },
      deep: true
    },
    dataInternal: {
      handler(newValue) {
        this.$emit("input", newValue)
      },
      deep: true
    },
    "customers.search": {
      handler(newValue) {
        this.searchCustomers()
      }
    },
    "projects.search": {
      handler(newValue) {
        this.searchProjects()
      }
    }
  },
  methods: {
    t: function(key) {
      return this.$t("absences.reasons.detail." + key)
    },
    async searchCustomers() {
      this.customers.loading = true
      try {
        let response = await HTTP.get("customers/search?q=" + this.customers.search)
        this.customers.data = response.data
      } finally {
        this.customers.loading = false
      }
    },
    async searchProjects() {
      this.projects.loading = true
      try {
        let response = await HTTP.get("customer-projects/search_by_customer?customer_id=" + this.customers.customer_id + "&q=" + this.projects.search)
        this.projects.data = response.data
      } finally {
        this.projects.loading = false
      }
    }
  }
}

</script>

<style scoped lang="scss">

</style>