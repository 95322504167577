<template>
  <div>
    <toolbar :dataLoaded="dataLoaded">{{ title }}</toolbar>

    <v-container fluid class="edit-screen no-hero-container">

      <v-row class="data-table-toolbar">
        <slot name="toolbar-buttons-left"></slot>
        <v-spacer></v-spacer>
        <slot name="toolbar-buttons-right"></slot>
      </v-row>

      <v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-0"
          :sort-by="initialSortBy"
          :sort-desc="initialSortDesc"
          :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': $t('table.items-per-page')
            }"
        >
          <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate />

          <template v-for="column in headers" :slot="`item.${column.value}`" slot-scope="{ item }">
            <slot :name="`column-${column.value}`" :item="item" :column="column"></slot>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import Vue from "vue"
import toolbar from "@/components/layouts/Navigation.vue"
import NoDataIndicator from "@/components/NoDataIndicator.vue"

export default Vue.extend({
  name: "IndexList",
  components: {
    toolbar,
    NoDataIndicator
  },
  props: {
    title: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    initialSortBy: {
      type: String,
      default: "name"
    },
    initialSortDesc: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      items: [],
      totalItems: 0,
      loading: false,
      dataLoaded: false,
      options: null
    }
  },
  computed: {
    hasData() {
      return !this.loading && this.dataLoaded && this.totalItems > 0
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        let newSerialized = JSON.stringify(newOptions)
        let oldSerialized = JSON.stringify(oldOptions)
        if (newSerialized !== oldSerialized) {
          this.fetchData()
        }
      },
      deep: true
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const data = await this.loadData(this.options)
        this.items = data.items
        this.totalItems = data.totalItems
        this.dataLoaded = true
      } catch (err) {
        this.$root.infoNotification.showResponseError(err)
      } finally {
        this.loading = false
      }
    },
    t(key) {
      return this.$t("index-list." + key)
    }
  },
  created() {
  }
})
</script>

<style language="scss" scoped>

</style>
